<template>
  <lab-task> <lab-titration :variation="variation" :conc-naoh="concNaOH" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabTitration, {Variation} from '../simulations/LabTitration';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import LabTask from './labs/LabTask';
export default Vue.extend({
  name: 'UPEITitration',
  components: {LabTitration, LabTask},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      variation: Variation.Upei,
    };
  },
  computed: {
    concNaOH() {
      return this.taskState.getValueBySymbol('concNaOH').content.toFloat();
    },
  },
});
</script>
